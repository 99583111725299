<template>
  <v-alert
    color="success"
    dense
    :icon="successIcon"
    outlined
    class="core-element-success-message"
  >
    <small>{{ message }}</small>
  </v-alert>
</template>

<script>
import { SUCCESS_ICON } from '../../../constants/app'

export default {
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data: () => ({
    successIcon: SUCCESS_ICON
  })
}
</script>
